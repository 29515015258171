import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faPlayCircle, faEnvelope, faChevronRight, faEye, faCheck } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (
      <Layout container={true}>
        <SEO title="Webseitenwiederherstellung - Wir reparieren Ihre Seite!"></SEO>
        <div className="loosepage text-center">
          <h1>Webseiten Notruf</h1>
          <p style={{ maxWidth: 500, margin: "auto" }}>Ihre Webseite wurde gehackt oder funktioniert seit einem Update nicht mehr? <span className="muted">Rufen Sie uns an!</span></p>
          <a href="tel:+4915258799871" className="btn btn-success-outline mt-2"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> +49 1525 87 99 87 1</a>
        </div>
        <div style={{ margin: "auto", maxWidth: "800px" }}>
            <Img style={{ borderRadius: "7px" }}                     src={require("../../images/services/first-aid.jpg")}/>
        </div>
        <div className="text-center" style={{margin:20}}>
          <Link className="btn btn-primary-outline" to="/mehr-erfahren">Mehr erfahren <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></Link>
        </div>
        <br />
        <br />
      </Layout >
    )
  }
}

